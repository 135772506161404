<template>
  <b-sidebar
    id="room-form-sidebar"
    :visible="isRoomFormSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @shown="getData(roomData)"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-room-form-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{headertext}}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2 form-blue form-boder-input"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Title"
            rules="required"
          >
            <b-form-group
              label="Title"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="Data.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Title"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="status"
          >
            <b-form-group
              label="status"
              label-for="status"
              class="mt-2"
            >
              <b-form-checkbox 
                id="status"
                v-model="Data.is_active"
                :state="getValidationState(validationContext)"
                trim name="check-button" 
                switch
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          
        

       
        


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormCheckbox, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { slugify } from '@/@fake-db/utils'
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isRoomFormSidebarActive',
    event: 'update:is-room-form-sidebar-active',
  },
  props: {
    isRoomFormSidebarActive: {
      type: Boolean,
      required: true,
    },
   
    roomData: {
      type: Object,
      required: true,
    },
    headertext:{
      type:String,
      required: true,
    }

  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
 
  setup(props, { emit }) {
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const toast = useToast()
    const blankData = {
    
      title: '',
      is_active:true,
      branch_id:userStorage.branch_id,
      created_by: null,
   
    }

    const Data = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      Data.value = JSON.parse(JSON.stringify(blankData))
    }
    const getData = (data)=>{
     
      if(data.title){ 
       
      
        Data.value  = JSON.parse(JSON.stringify(data))
      }
     
    
    }
    
    const onSubmit = () => {
      //default 
      Data.value.branch_id = userStorage.branch_id;
      Data.value.created_by = userStorage.id;
      //
      console.log(Data.value);
      if(Data.value.is_active == true){
        Data.value.is_active = 1;
      }
      else if(Data.value.is_active == false){
        Data.value.is_active = 0;
      }
      console.log(Data.value.is_active);
        if(Data.value.id){
          store.dispatch('app-room/updateData', Data.value)
          .then(() => {
            emit('refetch-data')
            emit('update:is-room-form-sidebar-active', false)
            toast({
            component: ToastificationContent,
            props: {
              title: "Save Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Save",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })

        }else{
          store.dispatch('app-room/addData', Data.value)
          .then(() => {
            emit('refetch-data')
            emit('update:is-room-form-sidebar-active', false)
            toast({
            component: ToastificationContent,
            props: {
              title: "Save Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Save",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })

        }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)


    return {
      Data,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      getData
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#room-form-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
